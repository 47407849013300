<template>
  <v-dialog
    overlay-color="rgba(0,0,0,0.4)"
    overlay-opacity="0.8"
    v-model="state"
    max-width="480"
    persistent
  >
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(addBranch)" class="px-3">
        <v-card flat ref="form">
          <v-card-title>
            <span class="ft font-size-lg font-weight-bold blue-grey--text ml-1"
              >Add Branch
            </span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-col>
              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-text-field
                    outlined
                    autofocus
                    v-model="branch.name"
                    label="Name"
                    class="ft font-weight-medium font-size-sm"
                    :error-messages="errors[0]"
                    hint="Name of branch"
                  />
                </ValidationProvider>
              </v-row>
              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required|max:13"
                >
                  <v-text-field
                    outlined
                    class="ft font-weight-medium font-size-sm"
                    v-model="branch.telephoneNumber"
                    label="Telephone Number"
                    :error-messages="errors[0]"
                    hint="Branches telephone number"
                  />
                </ValidationProvider>
              </v-row>
              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-text-field
                    outlined
                    class="ft font-weight-medium font-size-sm"
                    v-model="branch.location"
                    label="Location"
                    :error-messages="errors[0]"
                    hint="Enter branch location"
                  />
                </ValidationProvider>
              </v-row>
              <v-row>
                <ValidationProvider
                  tag="div"
                  class="d-flex flex-row flex-grow-1"
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-autocomplete
                    :items="regions"
                    outlined
                    label="Region"
                    v-model="branch.region"
                    :error-messages="errors[0]"
                    class="ft font-weight-medium font-size-md"
                  >
                    <template #item="{item}">
                      <span class="ft font-weight-medium font-size-sm">{{
                        item
                      }}</span>
                    </template>
                  </v-autocomplete>
                </ValidationProvider>
              </v-row>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="close"
              class="ft font-weight-medium font-size-sm text-capitalize"
              text
            >
              Cancel
            </v-btn>
            <v-btn
              type="submit"
              :loading="loading"
              :disabled="loading"
              color="primary"
              class="ft font-weight-medium font-size-sm text-capitalize"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { email, max, numeric, required } from "vee-validate/dist/rules";
import { mapGetters } from "vuex";

extend("required", {
  ...required,
  message: "Field is required"
});
extend("email", {
  ...email,
  message: "Invalid email provided"
});
extend("numeric", {
  ...numeric,
  message: "Field accept only numbers"
});
extend("max", {
  ...max,
  message: "Invalid phone number provided"
});
export default {
  name: "AddBranchAdmin",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  computed: {
    ...mapGetters({
      resetFormState: "getResetFormState"
    })
  },
  props: {
    state: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Boolean,
      default: false
    },
    regions: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    branch: {
      name: "",
      telephoneNumber: "",
      location: "",
      region: ""
    },
    reset: {
      name: null,
      telephoneNumber: null,
      location: null,
      region: null
    }
  }),
  watch: {
    resetFormState(value) {
      if (value) {
        this.branch = { ...this.reset };
      }
      setTimeout(() => {
        this.$store.dispatch("resetFormState", false, { root: true });
      }, 2000);
    }
  },
  methods: {
    close() {
      this.$emit("actions", "details");
    },
    addBranch() {
      this.$emit("branch", this.branch);
    }
  },
  created() {}
};
</script>
